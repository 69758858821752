<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1>{{ translations.tcPersonalWitnessingOpportunities }}</h1>
    </header>

    <div>
      <camp-selector
        @camp_changed="page_load()"
        :tooltip="translations.tcCurrentHome"
        :i18n="translations.components"
      ></camp-selector>
      <!-- / Camp Selector -->
      <data-table
        :fields="dataTableFields"
        :items="opportunities"
        :hiddenItems="hiddenItems"
        :addItems="addItems"
        :includeAddButton="includeAddButton"
        :csvUrl="reportUrlCsv"
        :pdfUrl="reportUrlPdf"
        :searchTerm="`witnessTerm`"
        @addWitnessingEncounter="addWitnessingEncounter"
        :i18n="translations.components"
        :reportName="reportName"
      >
      </data-table>
      <footer class="page-footer"></footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTable.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'personal-witnessing-encounters',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      opportunities: [],
      dataTableFields: [
        {
          key: 'wit_name',
          label: '', // 'Name'
          class: 'col col-3',
          sortable: true,
        },
        {
          key: 'wit_content',
          label: '', // 'Request'
          class: 'col col-6',
        },
        {
          key: 'wit_add_date',
          label: '', // 'Date Added'
          class: 'col col-3',
          sortable: true,
        },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: true,
        text: '', // 'Add Witnessing Opportunity
        action: 'addWitnessingEncounter',
      },
      includeAddButton: true,
      reportName: 'PersonalWitnessingOpportunities',
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([this.getViewTranslations(), this.getComponentTranslations('data-table', 'camp-select')]).then(
        (results) => {
          const componentTranslations = results[1]
          this.$set(this.translations, 'components', componentTranslations)
          this.loadTranslations()
        }
      )
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      loadAllOpportunities: 'prayerAndWitnessing/loadAllOpportunities',
      resetSelectedEncounter: 'prayerAndWitnessing/resetSelectedEncounter',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([await this.loadAllOpportunities()]).then(() => {
          this.opportunities = this.translateDates()
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async addWitnessingEncounter() {
      await Promise.all([await this.resetSelectedEncounter()]).then(() => {
        this.$router.push({
          path: '/programs/pw/add-witnessing-encounter',
        })
      })
    },
    loadTranslations() {
      this.dataTableFields[0].label = this.translations.tcName
      this.dataTableFields[1].label = this.translations.tcRequest
      this.dataTableFields[2].label = this.translations.tcDateAdded
      this.addItems.text = this.translations.tcAddWitnessingOpportunity
    },
    translateDates() {
      if (!this.loaded_opportunities) return false
      const formatOptions = { dateStyle: 'medium' }

      return this.loaded_opportunities.map((opp) => {
        if (!opp.wit_add_date) return opp

        const formattedDate = this.returnFormattedDate(opp.wit_add_date, formatOptions)
        return { ...opp, wit_add_date: formattedDate }
      })
    },
  },
  computed: {
    ...mapGetters({
      loaded_opportunities: 'prayerAndWitnessing/opportunities',
      prefCulture: 'user/userPreferredCulture',
      reportUrlPdf: 'prayerAndWitnessing/opportunityReportUrlPdf',
      reportUrlCsv: 'prayerAndWitnessing/opportunityReportUrlCsv',
    }),
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';
</style>
